interface IMetaTagsProps {
  title: string
  description?: string
}

export const MetaTags: Settle.FC<IMetaTagsProps> = ({ title, description }) => (
  <>
    <title>{title}</title>
    {description && <meta name="description" content={description} key="desc" />}

    {/* Facebook */}
    <meta property="og:title" content={title} />
    {description && <meta property="og:description" content={description} />}
    <meta property="og:image" content="/og/settle.png" />

    {/* Twitter */}
    <meta property="twitter:title" content={title} />
    {description && <meta property="twitter:description" content={description} />}
    <meta property="twitter:card" content="summary_large_image" />
    <meta property="twitter:image" content="/og/settle.png" />
  </>
)
