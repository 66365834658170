/* eslint-disable no-useless-escape */
export const EMAIL_PATTERN =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const WEBSITE_PATTERN = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi
export const EIN_TIN_PATTERN = /^(?:[0-9]{9}|[0-9]{3}-[0-9]{2}-[0-9]{4}|[0-9]{2}-[0-9]{7})$/
export const SSN_LAST_FOUR_PATTERN = /^[0-9]{4}$/
export const SSN_PATTERN = /^(?:[0-9]{9}|[0-9]{3}-[0-9]{2}-[0-9]{4})$/
export const ADDRESS_FIELD_PATTERN = /^[A-Za-z\d\/?:().,'+ -]*$/gm
/**
 * @description This pattern will match any string that only contains the characters 0-9, +, (, ), -, and whitespace.
 */
export const BASIC_PHONE_PATTERN = /^[+\d\s()-]*$/
export const PAYMENT_REFERENCE_PATTERN = /^[0-9A-Za-z &'()*+,-.\\:=?]*$/
